/* eslint-disable react/forbid-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';

import CmsBlock from 'Component/CmsBlock';
import CmsPopup from 'Component/CmsPopup';
import ContentWrapper from 'Component/ContentWrapper';
// import { CART, MENU, MENU_SUBCATEGORY } from 'Component/Header/Header.config';
import { Page } from '@scandipwa/scandipwa/src/component/Header/Header.config'
import Image from 'Component/Image';
// import LegalNotesPopup from 'Component/LegalNotesPopup';
import { LEGAL_NOTES_POPUP } from 'Component/LegalNotesPopup/LegalNotesPopup.config';
import NewsletterCustom from 'Component/NewsletterCustom';
import ScrollToTop from 'Component/ScrollToTop';
import TempiDiConsegnaBanner from 'Component/TempiDiConsegnaBanner';
import {
    FooterComponent as SourceFooter
} from 'SourceComponent/Footer/Footer.component';

import './Footer.override.style';

export const HIDE_FOOTER_STATES_DESKTOP = [
];

export const HIDE_FOOTER_STATES_MOBILE = [
    Page.MENU,
    Page.CART,
    Page.MENU_SUBCATEGORY
];

/** @namespace Pwa/Component/Footer/Component/FooterComponent */
export class FooterComponent extends SourceFooter {
    static propTypes = {
        ...SourceFooter.propTypes,
        navigationState: PropTypes.object.isRequired
    };

    shouldComponentUpdate(nextProps) {
        const {
            device: {
                isMobile,
            },
            isVisibleOnMobile,
            copyright,
            newsletterActive,
            isVisible
        } = this.props;

        const {
            device: {
                isMobile: nextIsMobile,
            },
            isVisibleOnMobile: nextIsVisibleOnMobile,
            copyright: nextCopyright,
            newsletterActive: nextNewsletterActive,
            isVisible: nextIsVisible
        } = nextProps;

        return isMobile !== nextIsMobile
            || isVisibleOnMobile !== nextIsVisibleOnMobile
            || copyright !== nextCopyright
            || newsletterActive !== nextNewsletterActive
            || isVisible !== nextIsVisible;
    }

    shouldIncludeFooter(navigationStateName) {
        const { device, showFooter, hideFooter } = this.props;
        const statesConfig = device.isMobile ? HIDE_FOOTER_STATES_MOBILE : HIDE_FOOTER_STATES_DESKTOP;

        if (!statesConfig.includes(navigationStateName)) {
            showFooter();
        } else {
            hideFooter();
        }
    }

    componentDidUpdate(prevProps) {
        const { navigationState: { name: prevName } } = prevProps;
        const { navigationState: { name } } = this.props;

        if (prevName !== name) {
            this.shouldIncludeFooter(name);
        }
    }

    componentDidMount() {
        const self = this
        const { navigationState: { name } } = self.props;
        self.shouldIncludeFooter(name);

        // CUSTOM EVENT
        document.addEventListener("UPDATE_NAVIGATION_AFTER", async (event) => {
            const { navigationState: { name } } = self.props;
            self.shouldIncludeFooter(name);
        })
        
    }

    renderCmsBlockWrapper() {
        const { footer_content: { footer_cms } = {} } = window.contentConfiguration;
        
        return (
            <div
                block="Footer"
                elem="CmsBlockWrapper"
                mix={{ block: 'Footer', elem: 'Content' }}
            >
                <div
                    block="Footer"
                    elem="Columns"
                    mix={{ block: 'ContentWrapper' }}
                >
                    <CmsBlock identifier={footer_cms} />
                </div>
            </div>
        );
    }

    renderContent() {
        const { footer_content: { footer_cms = undefined } = {} } = window.contentConfiguration || {};

        if (footer_cms) {
            return this.renderCmsBlockWrapper();
        }

        return (
            <div
                block="Footer"
                elem="CmsBlockWrapper"
                mix={{ block: 'Footer', elem: 'Content' }}
            >
                <div
                    block="Footer"
                    elem="Columns"
                    mix={{ block: 'ContentWrapper' }}
                >
                    <div block="CmsBlock" elem="Wrapper" mix={{ block: 'CmsBlock-Wrapper_type_' }}>
                        <div block="container" elem="fluid">
                            <div block="containerStyle">
                                <div block="block" elem="wrapper" mix={{ block: 'CmsBlock-one' }}>
                                    <CmsBlock identifier="footer_bottom_5" />
                                </div>
                                <div block="block" elem="wrapper" mix={{ block: 'CmsBlock-second' }}>
                                    <CmsBlock identifier="footer_bottom_2" />
                                </div>
                                <div block="block" elem="wrapper" mix={{ block: 'CmsBlock-third' }}>
                                    <CmsBlock identifier="footer_bottom_4" />
                                </div>
                                <div block="block" elem="wrapper" mix={{ block: 'CmsBlock-fourth' }}>
                                    <CmsBlock identifier="footer_bottom_1" />
                                </div>
                            </div>
                            <div block="row" mix={{ block: 'desktopPayment' }}>
                                <div block="block" elem="wrapper">
                                    <CmsBlock identifier="footer_bottom_6" />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        );
    }

    renderAltro() {
        return (
            <ContentWrapper
                mix={{ block: 'Footer', elem: 'AltroContentWrapper' }}
                wrapperMix={{ block: 'Footer', elem: 'AltroContent' }}
                label=""
            >
                <CmsBlock identifier="footer-altro" />
            </ContentWrapper>
        );
    }

    renderCopyrightContent() {
        const { copyright } = this.props;
        const imgSrc = '/media/wysiwyg/loghi/logo-light.png';
        return (
            <ContentWrapper
                mix={{ block: 'Footer', elem: 'CopyrightContentWrapper' }}
                wrapperMix={{ block: 'Footer', elem: 'CopyrightContent' }}
                label=""
            >
                <Image
                    src={imgSrc}
                    mix={{
                        block: 'Footer',
                        elem: 'Logo'
                    }}
                />
                <span block="Footer" elem="Copyright">
                    ©MondoConvenienza
                    <br />
                    {copyright}
                    |&nbsp;
                    {this.renderShareWishlistButton()}
                </span>

            </ContentWrapper>
        );
    }

    renderShareWishlistButton() {
        const {
            showPopup
        } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <span
                block="Footer"
                elem="LegalNotes"
                role="button"
                tabIndex="0"
                onClick={showPopup}
            >
                {__('Company data')}
            </span>
        );
    }

    renderNewsletterPanel() {
        if (window.location.pathname === '/trasporto-e-montaggio') {
            return null;
        }
        if (window.location.pathname === '/promozione-camerette-coupon') {
            return null;
        }
        if (window.location.pathname === '/promozione-camere-coupon') {
            return null;
        }
        if (window.location.pathname === '/lp-mcday2022') {
            return null;
        }
        if (window.location.pathname === '/super-saldi-arredamento') {
            return null;
        }

        if (window.location.pathname === '/dolce-casa') {
            return null;
        }

        return (
            <NewsletterCustom />
        );
    }

    renderLegalNotesPopup() {
        const legal_notes = 'legal_notes';

        return (
            <CmsPopup
                id={LEGAL_NOTES_POPUP}
                cms_block={legal_notes}
                blockType="Legal"
            />
        );
    }

    renderTempiDiConsegnaBanner() {
        return (
            <TempiDiConsegnaBanner />
        );
    }

    render() {
        
        // const { isVisibleOnMobile, device } = this.props;
        //
        // if (!isVisibleOnMobile && device.isMobile) {
        //     return null;
        // }
        //
        // if (isVisibleOnMobile && !device.isMobile) {
        //     return null;
        // }

        const { isVisible } = this.props;

        if (window.location.pathname === '/concorsocatanzaro') {
            return null;
        }

        return isVisible && (
            <footer block="Footer" aria-label="Footer">
                {(window.location.pathname !== '/black-friday') && (
                    this.renderNewsletterPanel()
                )}
                {this.renderContent()}
                {this.renderAltro()}
                {this.renderCopyrightContent()}
                {this.renderLegalNotesPopup()}
                <ScrollToTop />
            </footer>
        );
    }
}

export default FooterComponent;
