import { connect } from 'react-redux';
import { NavigationType } from '@scandipwa/scandipwa/src/store/Navigation/Navigation.type'

import { LEGAL_NOTES_POPUP } from 'Component/LegalNotesPopup/LegalNotesPopup.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    FooterContainer as SourceFooterContainer
} from 'SourceComponent/Footer/Footer.container';
// import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showPopup } from 'Store/Popup/Popup.action';

import Footer from './Footer.component';

/** @namespace Pwa/Component/Footer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    navigationState: state.NavigationReducer[NavigationType.TOP_NAVIGATION_TYPE].navigationState,
    state: state
});

/** @namespace Pwa/Component/Footer/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showPopup: (payload) => dispatch(showPopup(LEGAL_NOTES_POPUP, payload))
});

export class FooterContainer extends SourceFooterContainer {

    __construct(props) {
        super.__construct?.(props);

        this.state = {
            isVisible: true
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        showFooter: () => this.setState({ isVisible: true }),
        hideFooter: () => this.setState({ isVisible: false })
    };

    containerProps() {
        return {
            ...super.containerProps(),
            ...this.props,
            ...this.state
        };
    }

    render() {

        return (
            <Footer
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
